ul.list--arrowed {
  li {
    list-style-type: none;
    position: relative;
    padding-bottom: 0.5rem;
    padding-left: 1.3rem;

    &::before {
      content: '>';
      font-size: 1.2rem;
      position: absolute;
      left: 0;
      top: 0.3rem;
    }
  }
}

.vl-modal .vl-action-group .vl-button {
  margin-left: 0;
}
