@import '../../../ui-flanders/themes/_shared/scss/abstract/mixins';
@import '../../../ui-flanders/themes/_shared/scss/abstract/variables';

iframe {
  @include breakpoint(medium) {
    overflow-x: hidden;
    margin-left: 0;
  }
}

.extra-login {
  margin: 0;
  padding-left: 1.5rem;

  @include breakpoint(medium) {
    padding-left: 3rem;
  }

  @include breakpoint(large) {
    padding-left: 3rem;
  }
}
