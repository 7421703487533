.vl-header {
  .site-bar {
    &__profile {
      margin-right: 1rem;
      cursor: pointer;
      position: relative;
      top: 1rem;
      display: none;
      float: right;

      .vl-vi-arrow-down-fat {
        color: $vl-highlighted-action-color;
      }

      .vl-popover__content {
        text-align: left;
        padding: 0 0 1rem 1rem;
        max-height: 30rem;
        border-radius: 0.3rem;
        border: 1px solid $vl-ghost;

        .profile-modal__list {
          max-height: 30rem;
          overflow-y: auto;

          h4 {
            display: none;
          }

          h5 {
            margin-top: 1rem;
          }
        }
      }

      @media only screen and (min-width: $vl-bp-medLarge) {
        display: block;
      }
    }

    &__profile-mobile {
      border: none;
      display: none;
      cursor: pointer;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 600;
      position: relative;
      margin: 3rem auto 3rem 3rem;

      .profile-arrowDown {
        color: $vl-highlighted-action-color;
        vertical-align: text-top;
      }

      .profile-badge {
        display: block;
        margin: 0 auto;

        .vl-badge__icon {
          font-size: 4rem;
        }
      }
    }
  }
  &.mobileNavIsOpen {
    .site-bar {
      &__profile-mobile {
        display: inline-block;

        @media only screen and (min-width: $vl-bp-medLarge) {
          display: none;
        }
      }
    }
  }
}
.profile-modal__list {
  &-item {
    ~ .profile-modal__list-item {
      .vl-link {
        border-top: 0.1rem solid $vl-ghost;
      }
    }

    .vl-link {
      align-items: flex-start;
      display: flex;
      margin: 0;
      padding: 1rem 0;
      text-decoration: none;
      width: 100%;
    }

    .user-profile {
      &__badge {
        color: $vl-black;
        font-size: 2.4rem;
        margin: 0 1rem 0 0;
      }

      &__name {
        color: $vl-highlighted-action-color;
        font-size: 1.8rem;
        font-weight: 600;
      }

      &__customerId {
        color: $vl-black;
        display: block;
      }
    }
  }
  &-item--main {
    color: $vl-black;
    display: flex;

    .user-profile {
      &__name {
        color: $vl-black;
      }
    }
  }
}
