.vl-slider--equal-height .flickity-resize .flickity-slider,
.vl-slider--equal-height .flickity-resize .flickity-viewport,
.vl-slider--equal-height .flickity-resize .vl-slide {
  height: 100%;
  transition: left 0.4s ease;
}

.vl-slider {
  // Reset z-index otherwise arrows could go over the SwModal
  z-index: 0;

  &.vl-slider--equal-height {
    min-height: auto;
    // .vl-slider--equal-height doens't work as expected
    // if there is a div after the .vl-slide. In this case: vl-info-tile
    .vl-slide {
      display: flex;
      flex-direction: column;
    }
    .vl-info-tile {
      flex: 1 1 auto;

      &__content {
        flex: 1 1 auto;
      }
    }
  }
}
.flickity-slider {
  width: 100%;
  height: 100%;
  .vl-slide {
    min-height: 100%;
  }
  .flickity-page-dot {
    margin-top: 2rem;
  }
}
