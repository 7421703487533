// Font generated with https://icomoon.io/
// See https://wiki.sodexo.be/display/PROJECTAAH/Iconfont for userguide
$icomoon-font-family: 'iconfont' !default;
$icomoon-font-path: '../../font/iconfont/' !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?bapw97')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?bapw97')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?bapw97##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='vl-vi-'],
[class*=' vl-vi-'],
.vl-vi::before,
.vl-vi::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
