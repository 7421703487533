// The tag <main> is unrecognized in IE11
main {
  display: block;
}

// Core
@import '~@govflanders/vl-ui-core/src/scss/core';

// Atoms

@import '~@govflanders/vl-ui-badge/src/scss/badge';
@import '~@govflanders/vl-ui-introduction/src/scss/introduction';
@import '~@govflanders/vl-ui-link/src/scss/link';
@import '~@govflanders/vl-ui-loader/src/scss/loader';
@import '~@govflanders/vl-ui-skiplink/src/scss/skiplink';
@import '~@govflanders/vl-ui-titles/src/scss/titles';
@import '~@govflanders/vl-ui-typography/src/scss/typography';
@import '~@govflanders/vl-ui-button/src/scss/button';
@import '~@govflanders/vl-ui-icon/src/scss/icon';

// Forms
@import '~@govflanders/vl-ui-checkbox/src/scss/checkbox';
@import '~@govflanders/vl-ui-form-message/src/scss/form-message';
@import '~@govflanders/vl-ui-input-addon/src/scss/input-addon';
@import '~@govflanders/vl-ui-input-field/src/scss/input-field';
@import '~@govflanders/vl-ui-input-group/src/scss/input-group';
@import '~@govflanders/vl-ui-form-structure/src/scss/form-structure';
@import '~@govflanders/vl-ui-datepicker/src/scss/datepicker';
@import '~@govflanders/vl-ui-select/src/scss/select';
@import '~@govflanders/vl-ui-autocomplete/src/scss/autocomplete';
@import '~@govflanders/vl-ui-upload/src/scss/upload';
@import '~@govflanders/vl-ui-textarea/src/scss/textarea';

// Components
@import '~@govflanders/vl-ui-alert/src/scss/alert';
@import '~@govflanders/vl-ui-icon-list/src/scss/icon-list';
@import '~@govflanders/vl-ui-action-group/src/scss/action-group';
@import '~@govflanders/vl-ui-info-tile/src/scss/info-tile';
@import '~@govflanders/vl-ui-data-table/src/scss/data-table';
@import '~@govflanders/vl-ui-document/src/scss/document';
@import '~@govflanders/vl-ui-modal/src/scss/modal';
@import '~@govflanders/vl-ui-pager/src/scss/pager';
@import '~@govflanders/vl-ui-pill/src/scss/pill';
@import '~@govflanders/vl-ui-link-list/src/scss/link-list';
@import '~@govflanders/vl-ui-popover/src/scss/popover';
@import '~@govflanders/vl-ui-wizard/src/scss/wizard';
@import '~@govflanders/vl-ui-progress-bar/src/scss/progress-bar';
@import '~@govflanders/vl-ui-breadcrumb/src/scss/breadcrumb';
@import '~@govflanders/vl-ui-dropdown-navigation/src/scss/dropdown-navigation';
@import '~@govflanders/vl-ui-description-data/src/scss/description-data';
@import '~@govflanders/vl-ui-radio/src/scss/radio';
@import '~@govflanders/vl-ui-functional-header/src/scss/functional-header';
@import '~@govflanders/vl-ui-search/src/scss/search';
@import '~@govflanders/vl-ui-tabs/src/scss/tabs';
@import '~@govflanders/vl-ui-properties/src/scss/properties';
@import '~@govflanders/vl-ui-slider/src/scss/slider';

// Utilities
@import '~@govflanders/vl-ui-tooltip/src/scss/tooltip';
@import '~@govflanders/vl-ui-util/src/scss/util';

.vl-u-text--accent {
  color: $vl-accent-color !important;
}

.vl-u-text--action {
  color: $vl-action-color !important;
}

.vl-data-table--collapsed-s.vl-data-table--lined td:not(:first-child),
.vl-data-table--collapsed-s.vl-data-table--lined th:not(:first-child) {
  @include respond-to(small) {
    border-left: 0;
  }
}
.vl-data-table--collapsed-m.vl-data-table--lined td:not(:first-child),
.vl-data-table--collapsed-m.vl-data-table--lined th:not(:first-child) {
  @include respond-to(medium) {
    border-left: 0;
  }
}

.vla-timer--content {
  color: $vl-action-color;
  font-size: 32px;
}

.vla-passwordRequirements--container {
  text-align: start;
  .vla-passwordRequirements--content {
    list-style-type: disc;
    li {
      margin-left: 24px;
    }
  }
}
