.vl-button {
  border-radius: 0.2rem;
  transition: all 0.4s ease-in-out;
  /*white-space: normal;
  height: auto;
  min-height: 3.5rem;
  line-height: 1.5em;
  padding-top: 5px;
  padding-bottom: 5px;*/

  &--secondary {
    &.vl-button--large {
      line-height: 3.1rem;
    }
  }

  &--block {
    padding: 0 1rem;
  }
}

.vl-action-group {
  width: 100%;

  &--collapse--s {
    [class^='vl-button'],
    [class*='vl-button'] {
      width: 100%;
      justify-content: center;
      text-align: center;

      @include breakpoint(small) {
        width: auto;
      }

      .vl-icon {
        width: auto;
      }
    }
  }
}
