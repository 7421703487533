.js-vl-multiselect {
  [class$='-menu'] {
    top: 3.6rem;
    border-radius: 0;
  }
  [class$='-control'] {
    border-radius: 0;
    [class$='-multiValue'] {
      background-color: #e8ebee;
      font-weight: 500;
      color: #666;
      vertical-align: bottom;
      border-radius: 0.6rem;
      border: 0.1rem solid #cbd2da;
      transition: color 0.2s, background-color 0.2s;
      div:first-child {
        font-size: 1.4rem;
        color: #666;
      }
      div:nth-child(2) {
        border-left: 0.1rem solid #cbd2da;
        width: 2.4rem;
        &:hover {
          background-color: #e8ebee;
          color: #666;
        }
      }
    }
  }
}
