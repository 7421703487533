.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background: $pink;
  box-shadow: none;
  color: #fff;
  border-color: $pink;
}
.flatpickr-day:hover,
.flatpickr-day:focus,
.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  color: $pink !important;
  background: $light !important;
  border-color: transparent !important;
  font-weight: 500;
}

.flatpickr-calendar,
.flatpickr-innerContainer {
  border-radius: 18px;
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after,
.flatpickr-calendar.arrowBottom::before,
.flatpickr-calendar.arrowBottom::after {
  margin-left: -5px;
}
