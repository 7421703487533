/*
 * Bxl has his content centered 🎉 
 */
.vl-grid {
  justify-content: center;

  &--align-start {
    justify-content: flex-start;
  }

  &--align-end {
    justify-content: flex-end;
  }
}

/*
 * GRID
 * col-10 used as default 
 * col-12 used for dashboard / full page content
 *
 * PageHeader stay as col-8-12 otherwise, content is not well displayed
 * As content is centered and to have a good alignement on the page with col-10,
 * we add the class "push-pageHeader" on it to adapt his alignment and the alert width
 * Not needed when the page is on col-12
 */
.push-pageHeader {
  @include breakpoint(medium) {
    padding-right: 16.66667%;

    + .vl-alert-wrapper {
      width: 83.33333%;
    }
  }
}

.vl-alert-wrapper {
  width: 100%;
}

.hideMobile {
  display: none;
  @media only screen and (min-width: $vl-bp-medLarge) {
    display: block;
  }
}

.showMobile {
  display: block;

  @media only screen and (min-width: $vl-bp-medLarge) {
    display: none;
  }
}
