@import '../../../../../ui-flanders/themes/wl/scss/abstract/mixins';
@import '../../../../../ui-flanders/themes/wl/scss/abstract/variables';

footer {
  .footer--wrapper {
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint(small) {
      justify-content: space-between;
    }

    &_top {
      align-items: flex-end;

      @include breakpoint(medium) {
        align-items: center;
      }
    }
  }

  .footer--top {
    background-color: $footer;
    padding: 20px 0;

    @include breakpoint(medium) {
      padding: 40px 0;
    }

    .logo {
      width: 5rem;
      height: 4.65rem;
      display: block;
      margin-bottom: 9px;

      &--tagline {
        color: $vl-white;
        font-weight: 600;
        font-style: italic;

        span {
          display: block;
          color: $pink;
          font-size: 1.2rem;
        }
      }
    }

    &_list {
      width: 100%;

      li {
        margin-top: 20px;
      }

      .vl-button {
        min-width: 250px;
        justify-content: center;
        width: 100%;

        @include breakpoint(small) {
          width: auto;
        }
      }

      @include breakpoint(small) {
        width: 50%;

        li {
          text-align: right;
        }
      }

      @include breakpoint(medium) {
        display: flex;
        width: auto;

        li {
          margin-top: 0;
          margin-left: 20px;
        }
      }
    }
  }

  .footer--bottom {
    background-color: $vl-black;
    color: $vl-white;
    padding: 20px 0;

    .copyright {
      width: 100%;
      font-size: 1.4rem;
      margin-bottom: 20px;

      @include breakpoint(small) {
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      li {
        width: 100%;

        @include breakpoint(small) {
          width: auto;
          margin-left: 40px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 19px;
            left: -20px;
            width: 5px;
            height: 5px;
            background: $pink;
            border-radius: 50%;
          }
        }

        &:first-of-type {
          margin: 0;

          &::before {
            display: none;
          }
        }

        a {
          font-size: 1.4rem;
          color: $vl-white;
          padding: 10px 0;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $vl-white;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
