.onboarding {
  &-title {
    color: $vl-highlighted-action-color !important;
  }

  &-img-wrapper {
    display: flex;
    flex-grow: 1;
    background: #fe8da5 !important;
  }
}
