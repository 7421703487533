.vl-action-group .vl-link--disabled {
  color: #80807d;
  cursor: default;

  &:hover,
  &:focus {
    color: #80807d;
    text-decoration: underline;
  }
}
