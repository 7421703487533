.vl-progress-bar {
  &__step,
  &__step--active {
    &::before {
      background-color: $vl-success-color; // bar
    }
  }

  &__bullet,
  &__step--active .vl-progress-bar__bullet {
    background-color: $vl-success-color;
    border-color: $vl-success-color;
  }

  &__bullet::before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2Zm0,1.25A8.75,8.75,0,1,0,20.75,12,8.75,8.75,0,0,0,12,3.25ZM16.51,7.3a.63.63,0,1,1,1,.78h0L11,16.34a1.52,1.52,0,0,1-1.24.59,1.48,1.48,0,0,1-1.19-.65h0l-2-2.9a.62.62,0,1,1,1-.72h0l2.05,2.91a.24.24,0,0,0,.2.11.24.24,0,0,0,.21-.11h0Z' fill='%23fff' /%3E%3C/svg%3E");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $vl-success-color;
    z-index: 1;
  }

  &__step--active .vl-progress-bar__bullet::before,
  &__step--active ~ .vl-progress-bar__step .vl-progress-bar__bullet::before {
    display: none;
  }
}
.vl-progress-bar--numeric .vl-progress-bar__step {
  height: 4rem;
  &::before {
    top: 2rem;
  }

  .vl-progress-bar__bullet {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    right: 0.7rem;

    &::before {
      width: 4rem;
      height: 4rem;
    }

    &::after {
      font-size: 2rem;
      font-weight: 700;
      width: 4rem;
      height: 4rem;
    }
  }
}

.vl-progress-bar__bullet::before {
  width: 1.6rem;
  height: 1.6rem;
}
