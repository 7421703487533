@import '../../../../ui-flanders/themes/_shared/scss/abstract/mixins';
@import '../../../../ui-flanders/themes/_shared/scss/abstract/variables';

.exportModal {
  .vl-action-group {
    flex-wrap: wrap;

    .vl-link {
      width: 100%;
      padding: 5px 0;

      ~ .vl-link {
        margin-top: 1.5rem;
      }
    }

    .vl-icon {
      color: #333;
      margin-right: 0.5rem;
    }

    .download-link .vl-icon {
      font-size: 1.25em;
    }

    @include breakpoint(small) {
      justify-content: flex-start;

      .vl-link {
        width: auto;
        padding: 0;

        ~ .vl-link {
          margin-top: 0;
        }
      }

      .download-link {
        position: relative;
        margin-right: 4rem;

        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: #cecece;
            right: -2rem;
          }
        }
      }
    }
  }
}
