.js-vl-slider__previous,
.js-vl-slider__next {
  width: 3rem;
  height: 3rem;

  @include breakpoint(large) {
    border: 1px solid $vl-border-color;
    border-radius: 100%;
    transition: border-color 0.3s ease-in-out;

    &:focus,
    &:hover {
      border-color: $vl-action-hover-color;
    }

    &--disabled {
      &:focus,
      &:hover {
        border-color: $vl-border-color;
      }
    }
  }

  &::before {
    content: '';
    top: 0.2rem;
    width: 2.4rem;
    height: 2.4rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.js-vl-slider__previous {
  @include breakpoint(large) {
    left: -6rem;
  }

  &::before {
    left: 0.1rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M12 16c0-0.4 0.133-0.8 0.533-1.2l6.933-6.533c0.4-0.4 1.2-0.4 1.6 0.133 0 0 0 0 0.133 0.133 0.4 0.533 0.4 1.2-0.133 1.733l-6.133 5.733c-0.133 0.133-0.133 0.133 0 0.267 0 0 0 0 0 0l6.133 5.733c0.533 0.533 0.533 1.2 0.133 1.733s-1.067 0.533-1.6 0.133c0 0 0 0 0 0l-6.933-6.533c-0.533-0.533-0.667-0.933-0.667-1.333z' fill='%232d87c3'%3E%3C/path%3E%3C/svg%3E");
  }
}
.js-vl-slider__next {
  right: -0.3rem;
  @include breakpoint(large) {
    right: -3rem;
  }

  &::before {
    left: 0.2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M21.333 16c0 0.4-0.133 0.8-0.533 1.2l-6.933 6.533c-0.4 0.4-1.2 0.4-1.6-0.133 0 0 0 0-0.133-0.133-0.4-0.533-0.4-1.2 0.133-1.733l6.133-5.733c0.133-0.133 0.133-0.133 0-0.267 0 0 0 0 0 0l-6-5.6c-0.533-0.533-0.533-1.2-0.133-1.733s1.067-0.533 1.6-0.133c0 0 0 0 0 0l6.933 6.533c0.4 0.4 0.533 0.8 0.533 1.2z' fill='%232d87c3'%3E%3C/path%3E%3C/svg%3E%0A");
  }
}
