.vl-document__type {
  .vl-vi {
    color: $vl-black;
  }

  &__text {
    border-radius: 0.5rem;
    color: $vl-black;
    border: 0.3rem solid $vl-black;
    width: 3.5rem;
    top: -30px;
    right: -6px;
    height: 27px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}
