.contractList-card,
.profile-contractList-card {
  border-radius: 5px;

  &--active {
    border-color: $vl-highlighted-color !important;

    .vl-icon {
      color: $vl-highlighted-color !important;
    }
  }
}
