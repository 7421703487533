.vl-link {
  color: $vl-highlighted-action-color;

  &:hover,
  &:focus {
    color: $vl-action-hover-color;
  }
}

/*
 * Except in vl-alert to keep a good contrast
 */

.vl-alert {
  .vl-link {
    color: $vl-action-color;
  }
}
