/*
 * PageHeaderIntro stay in col-8-12 otherwise, content is not well displayed
 * Alert could be in col-10 or 12 depending of the page
 *
 * to use the col-10 display,
 * add class .push-pageHeader on PageHeader component
 */
.push-pageHeader {
  @media screen and (min-width: 767px) {
    + .vl-alert-wrapper {
      width: 83.33333%;
    }
  }
}
/*
 * Default
 * used with col-12
 */
.vl-alert-wrapper {
  width: 100%;
}
