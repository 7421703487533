.vl-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M0.42,9.77a3.27,3.27,0,0,1,.91-2.21,3.12,3.12,0,0,1,4.41,0L16,17.82,26.25,7.56A3.12,3.12,0,0,1,30.67,12L18.2,24.44a3.12,3.12,0,0,1-4.41,0L1.33,12A3.17,3.17,0,0,1,.42,9.77' fill='%23ed2345'/%3e%3c/svg%3e");
}

.vl-input-field {
  padding: 0 1.5rem;
}

.vl-textarea {
  border-radius: 1.5rem;
  padding: 0.7rem 1.5rem;
}
