$vl-base-font: 'Nunito Sans', sans-serif;
$vl-accent-font: 'Nunito Sans', sans-serif;

$input-border-radius: 18px;

// Height of the site-bar
$site-bar-height: 4.5rem;
// Breakpoints for navigation only
$vl-bp-medLarge: 1200px;

/* COLORS */

// WIP - colors from WL website
$main: #636363;
$dark: #071e22; // text-color
$light: #eeeeee;
$medium: #84828f;
$pink: #ed2345;
$medium-pink: #c8005d;
$dark-pink: #820068;
$light-pink: #fcd7dd;
$light-blue: #edf3f9;
$grey: #e1e1e1; // nav border-bottom
$footer: #1d1d1d;

$vl-white: #fff;
$vl-black: #000;
$vl-highlighted-color: $pink; // used in profile - ContractList

// override colors from vl-ui-core/src/scss-fresh/setting/_colors.scss
$vl-accent-color: #2f3e8b;
$vl-blue: #1e72ae;
/*
 Note that they already use $vl-action-color for CTA
 But we added a new $vl-highlighted-action-color put the accent on some links
*/
$vl-action-color: $pink;
$vl-action-hover-color: $medium-pink;
$vl-highlighted-action-color: $pink;

$vl-error-color: #ed2345;
$vl-error-secondary-color: #ff000019;
$vl-warning-color: #ffcc00;
$vl-warning-secondary-color: #fff6d2;
$vl-success-color: #9cbf10;
$vl-success-secondary-color: #f7fae5;

$vl-ghost: #f5f5f5;
$vl-alt-bg: #f1f1f1; // alert
$vl-alt-region-bg: #f1f1f1;

$vl-border-color: #8d8d8d;
$vl-disabled-bg-color: #e5e5e5; // used for input
$vl-disabled-button-bg-color: #a2a2a2; // new for Wl

$vl-ghost-nav-bg: #f4f6f7;
$vl-ghost-nav-color: #5f727f;
$vl-ghost-nav-border: #dfe5e8;

/// Breakpoints map to use in mixins respons
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'xsmall': 500px,
  'small': 767px,
  'medium': 1023px,
  'large': 1600px,
) !default;
