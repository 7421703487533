@import '../../../../../../ui-flanders/themes/wl/scss/abstract/mixins';
@import '../../../../../../ui-flanders/themes/wl/scss/abstract/variables';
@import './HeaderLogo.style.scss';
@import './HeaderMenuButton.style.scss';
@import './HeaderSupport.style.scss';
@import './HeaderSwitchLanguage.style.scss';
@import './HeaderSwitchProfile.style.scss';

.vl-region:first-child {
  padding-top: 6rem !important;
}

.vl-header {
  min-height: $site-bar-height;

  /* HEADER MENU WRAPPER NO MOBILE*/
  .vl-header__wrapper {
    min-height: $site-bar-height;
    position: fixed;
    overflow-y: auto;
    width: 100%;
    z-index: 10000;

    @include breakpoint(small) {
      position: relative;
      overflow-y: inherit;
    }
  }

  .site-bar {
    background-color: white;
    border-bottom: 0.1rem solid $vl-border-color;
  }
}

/* HEADER ON MOBILE */
.vl-header {
  /* WHEN NAV IS CLOSED */
  &.mobileNavIsClose {
    .site-nav {
      display: none;
    }
  }
  /* WHEN NAV IS OPEN */
  &.mobileNavIsOpen {
    .vl-header__wrapper {
      position: fixed;
      z-index: 1000;
      width: 100%;
      height: 100%;
      box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.2);

      @media only screen and (min-width: $vl-bp-medLarge) {
        position: relative;
        box-shadow: none;
      }

      @include breakpoint(xsmall) {
        position: absolute;
        height: auto;
      }
    }

    .site-nav {
      display: block;
      margin-top: 2rem;

      @media only screen and (min-width: $vl-bp-medLarge) {
        display: none;
      }
    }

    .site-bar {
      text-align: center;
      @media only screen and (min-width: $vl-bp-medLarge) {
        text-align: left;
      }
    }
  }
}
