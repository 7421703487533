/**
 * WARNING: Source Sans Pro & Nunito Sans doesn't have a font-weight: 500;
 * it should be replace by font-weight: 600
 **/
.vl-title--h3,
.vl-title--h4,
.vl-title--h5,
.vl-title--h6 {
  font-weight: 700;
}

strong,
.vl-u-text--bold,
.vl-typography strong,
.vl-typography b,
.vl-info-tile__header__title,
.vl-data-table th {
  font-weight: 700;
}

p {
  font-weight: 300; // not really convinced

  &.vl-introduction {
    font-weight: 400;
  }
}

.vl-introduction {
  color: black;
}
