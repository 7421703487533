.vl-alert {
  border: 0;

  &__content {
    width: calc(100% - 45px);
  }

  &--warning {
    .vl-alert__icon {
      color: #333 !important;
    }
  }
}
