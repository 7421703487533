.vl-button {
  overflow: hidden;
  height: 40px;
  margin: 0;
  padding: 0 25px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.05em;
  word-spacing: -1;
  user-select: none;
  border-radius: 25px;
  color: white;
  background-image: linear-gradient(
    270deg,
    $dark-pink 0%,
    $medium-pink 50%,
    $pink 100%
  );
  background-size: 200% auto;
  transition: all 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    background-position: right center;
  }

  &--secondary {
    color: $vl-action-color;
    background: none;
    border: 0.2rem solid $vl-action-color;

    /*&: &.vl-button--large {
      line-height: 3.1rem;
    }*/

    &:hover,
    &:focus,
    &:active {
      border-color: $vl-action-hover-color;
    }
  }

  &--disabled {
    background-image: none;
    background-color: $vl-disabled-button-bg-color;
    border-color: $vl-disabled-button-bg-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $vl-disabled-button-bg-color;
      border-color: $vl-disabled-button-bg-color;
    }

    &.vl-button--secondary {
      color: $vl-disabled-button-bg-color;

      &:hover,
      &:focus,
      &:active {
        background-color: white;
        border-color: $vl-disabled-button-bg-color;
        color: $vl-disabled-button-bg-color;
      }
    }
  }

  &--narrow {
    padding: 0 1rem;
  }

  &--loading {
    padding-right: 8rem !important;
    background-image: none !important;
  }
}

.vl-action-group--collapse--s {
  [class^='vl-button'],
  [class*='vl-button'] {
    width: 100%;

    @include breakpoint(small) {
      width: auto;
    }
  }
}
