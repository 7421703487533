@import '../../../../../ui-flanders/themes/_shared/scss/abstract/mixins';
@import '../../../../../ui-flanders/themes/_shared/scss/abstract/variables';

.relativesFormGroup {
  .js-vl-multiselect .vl-icon {
    color: #0055cc;
  }

  @include breakpoint(medium) {
    display: flex;

    .vl-form__group {
      background-color: transparent;
      margin: 0 0 0 auto; // always right align
      padding: 0;
      width: 50%;

      .js-vl-multiselect {
        [class$='-control'] {
          padding: 2px 0 4px;
        }
      }
    }
  }
}

.disableRelative {
  background-color: rgba(232, 235, 238, 0.3);
  color: #666666;

  .vl-badge {
    width: 2.2rem;
    height: 2.2rem;
    vertical-align: middle;
    margin-right: 1rem;

    .vl-vi-lock {
      font-size: 1.6rem;
      height: 1.6rem;
    }
  }
}
