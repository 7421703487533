.vl-badge {
  &--accent {
    .vl-icon {
      color: currentColor;
      fill: currentColor;
    }
  }
  &--xsmall {
    &.vl-badge--icon .vl-vi-info-circle {
      font-size: 1.8rem;
      height: 1.8rem;
    }
  }
  &--success,
  &--error {
    &.vl-badge--border {
      background-color: $vl-white;
    }
  }

  &--error {
    &.vl-badge--border {
      border: 0.2rem solid $vl-error-color;
      .vl-badge__icon {
        color: $vl-error-color;
      }
    }
  }

  &--success {
    &.vl-badge--border {
      border: 0.2rem solid $vl-success-color;
      .vl-badge__icon {
        color: $vl-success-color;
      }
    }
  }
}
