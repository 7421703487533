.vl-modal {
  &-dialog__close__icon {
    font-size: 2.4rem;
    font-weight: 700;
  }
  &-dialog--fullscreen {
    width: 95vw;
  }

  .vl-action-group {
    width: 100%;
    justify-content: space-between;

    .vl-button {
      // If there is only 1 item, align it to the right.
      &:only-child {
        align-self: flex-end;
        margin-left: auto;
      }
    }
  }
}
