.vl-modal-dialog {
  &--fullscreen {
    width: 100% !important;
    overflow-x: auto;
  }

  &__close {
    z-index: 5;
  }
}
