.vl-slider {
  &__frame .flickity-page-dots {
    margin: 2rem auto 0;
  }
}

.js-vl-slider {
  &__previous {
    &::before {
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M12 16c0-0.4 0.133-0.8 0.533-1.2l6.933-6.533c0.4-0.4 1.2-0.4 1.6 0.133 0 0 0 0 0.133 0.133 0.4 0.533 0.4 1.2-0.133 1.733l-6.133 5.733c-0.133 0.133-0.133 0.133 0 0.267 0 0 0 0 0 0l6.133 5.733c0.533 0.533 0.533 1.2 0.133 1.733s-1.067 0.533-1.6 0.133c0 0 0 0 0 0l-6.933-6.533c-0.533-0.533-0.667-0.933-0.667-1.333z' fill='%23ed2345'%3E%3C/path%3E%3C/svg%3E");
    }
  }
  &__next {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M21.333 16c0 0.4-0.133 0.8-0.533 1.2l-6.933 6.533c-0.4 0.4-1.2 0.4-1.6-0.133 0 0 0 0-0.133-0.133-0.4-0.533-0.4-1.2 0.133-1.733l6.133-5.733c0.133-0.133 0.133-0.133 0-0.267 0 0 0 0 0 0l-6-5.6c-0.533-0.533-0.533-1.2-0.133-1.733s1.067-0.533 1.6-0.133c0 0 0 0 0 0l6.933 6.533c0.4 0.4 0.533 0.8 0.533 1.2z' fill='%23ed2345'%3E%3C/path%3E%3C/svg%3E%0A") !important;
    }
  }
}
