.vl-vi-text-undo:before {
  content: '\e954';
}
.vl-vi-lightbulb:before {
  content: '\e950';
}
.vl-vi-auto-file:before {
  content: '\e94f';
}
.vl-vi-download-list-file:before {
  content: '\e94a';
}
.vl-vi-upload-list-file:before {
  content: '\e94b';
}
.vl-vi-works-add:before {
  content: '\e94c';
}
.vl-vi-hide:before {
  content: '\e94d';
}
.vl-vi-view-add:before {
  content: '\e94e';
}
.vl-vi-building:before {
  content: '\e93b';
}
.vl-vi-data-transfer:before {
  content: '\e916';
}
.vl-vi-scan:before {
  content: '\e941';
}
.vl-vi-field:before {
  content: '\e913';
}
.vl-vi-lock:before {
  content: '\e927';
}
.vl-vi-paperclip:before {
  content: '\e928';
}
.vl-vi-shipping-truck:before {
  content: '\e951';
}
.vl-vi-pick-up:before {
  content: '\e945';
}
.vl-vi-synchronize:before {
  content: '\e90e';
}
.vl-vi-calendar_check:before {
  content: '\e936';
}
.vl-vi-check-small:before {
  content: '\e910';
}
.vl-vi-alert-small:before {
  content: '\e911';
}
.vl-vi-menu:before {
  content: '\e934';
}
.vl-vi-dashboard:before {
  content: '\e905';
}
.vl-vi-programming-bug:before {
  content: '\e935';
}
.vl-vi-save:before {
  content: '\e942';
}
.vl-vi-bin:before {
  content: '\e937';
}
.vl-vi-location:before {
  content: '\e931';
}
.vl-vi-workspending:before {
  content: '\e926';
}
.vl-vi-hourglass:before {
  content: '\e92d';
}
.vl-vi-close:before {
  content: '\e923';
}
.vl-vi-data-upload:before {
  content: '\e91f';
}
.vl-vi-data-download:before {
  content: '\e920';
}
.vl-vi-logout:before {
  content: '\e921';
}
.vl-vi-settings:before {
  content: '\e902';
}
.vl-vi-bank-transfer:before {
  content: '\e91d';
}
.vl-vi-chat-help:before {
  content: '\e91c';
}
.vl-vi-search:before {
  content: '\e912';
}
.vl-vi-external:before {
  content: '\e944';
}
.vl-vi-document:before {
  content: '\e92c';
}
.vl-vi-file-new:before {
  content: '\e90f';
}
.vl-vi-file-upload:before {
  content: '\e93c';
}
.vl-vi-file-download:before {
  content: '\e93d';
}
.vl-vi-file-edit:before {
  content: '\e93e';
}
.vl-vi-file-settings:before {
  content: '\e93f';
}
.vl-vi-send-document:before {
  content: '\e914';
}
.vl-vi-voucher-scissors:before {
  content: '\e915';
}
.vl-vi-clock:before {
  content: '\e946';
}
.vl-vi-hammer:before {
  content: '\e917';
}
.vl-vi-ironing:before {
  content: '\e918';
}
.vl-vi-vaccum-cleaner:before {
  content: '\e919';
}
.vl-vi-shopping:before {
  content: '\e91a';
}
.vl-vi-car:before {
  content: '\e900';
}
.vl-vi-play:before {
  content: '\e952';
}
.vl-vi-pause:before {
  content: '\e953';
}
.vl-vi-question-mark-filled:before {
  content: '\e932';
}
.vl-vi-question-mark:before {
  content: '\e930';
}
.vl-vi-alert-circle-filled:before {
  content: '\e901';
}
.vl-vi-alert-circle:before {
  content: '\e92f';
}
.vl-vi-plus-circle:before {
  content: '\e91e';
}
.vl-vi-check-circle:before {
  content: '\e904';
}
.vl-vi-info-circle:before {
  content: '\e903';
}
.vl-vi-info:before {
  content: '\e92b';
}
.vl-vi-arrow-left-fat:before {
  content: '\e906';
}
.vl-vi-arrow-down-fat:before {
  content: '\e907';
}
.vl-vi-arrow-up-fat:before {
  content: '\e908';
}
.vl-vi-arrow-right-fat:before {
  content: '\e933';
}
.vl-vi-edit:before {
  content: '\e929';
}
.vl-vi-email:before {
  content: '\e91b';
}
.vl-vi-phone:before {
  content: '\e909';
}
.vl-vi-mobile-phone:before {
  content: '\e947';
}
.vl-vi-printer:before {
  content: '\e90a';
}
.vl-vi-envelope:before {
  content: '\e940';
}
.vl-vi-wifi-off:before {
  content: '\e948';
}
.vl-vi-user-circle-active:before {
  content: '\e90b';
}
.vl-vi-user-circle:before {
  content: '\e93a';
}
.vl-vi-profile-active:before {
  content: '\e924';
}
.vl-vi-user:before {
  content: '\e925';
}
.vl-vi-user-signup:before {
  content: '\e939';
}
.vl-vi-user-group:before {
  content: '\e949';
}
.vl-vi-burgerprofiel:before {
  content: '\e938';
}
.vl-vi-vouchers-list:before {
  content: '\e943';
}
.vl-vi-voucher:before {
  content: '\e90c';
}
.vl-vi-voucher-download:before {
  content: '\e922';
}
.vl-vi-voucher-check:before {
  content: '\e92e';
}
.vl-vi-wallet:before {
  content: '\e90d';
}
.vl-vi-calendar:before {
  content: '\e92a';
}
.vl-vi-film:before {
  content: '\e955';
}
