.site-bar {
  &__toggleMobile {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    cursor: pointer;
    height: 4.5rem;
    width: 4rem;
    padding: 1rem 1rem 0;

    span {
      background: $vl-blue;
      display: block;
      height: 0.2rem;
      left: 1rem;
      position: absolute;
      transform-origin: 50% 50%;
      transform: rotate(0deg);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease, width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        left 0.3s ease-in-out, right 0.3s ease-in-out, top 0.3s ease-in-out;
      width: 2rem;
      z-index: 1;

      &:first-child {
        top: 2rem;
      }
      &:nth-last-child(2) {
        top: 2.5rem;
      }
      &:last-child {
        top: 3rem;
      }
    }
  }
}

.site-bar__navHolder.isOpen {
  .site-bar__toggleMobile {
    span {
      left: 0.5rem;
      top: 2.5rem;
      width: 2.7rem;

      &:first-child {
        transform: rotate(45deg);
      }
      &:nth-last-child(2) {
        opacity: 0;
        transform: scale(0.2, 1);
      }
      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
