.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  // bg dark blue
  color: white;
}
// Fixed FireFox - prov - remittances search filters
.vl-datepicker {
  .vl-input-field {
    max-width: calc(100% - 3.5rem);
  }
}
