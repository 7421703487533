.vl-alert {
  &__title {
    font-weight: 700;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    [class*=' vl-vi-'] {
      font-size: 2.2rem;
      color: black;
      top: auto;
    }
  }

  &--success,
  &--error {
    .vl-alert__icon .vl-vi {
      color: white;
    }
  }

  &--success .vl-alert__icon .vl-vi {
    font-size: 2.2rem;
    top: auto;
  }

  &--small {
    .vl-alert__icon .vl-vi-info-circle {
      font-size: 1.8rem;
    }
  }
}
