.vl-badge--accent {
  background: linear-gradient(0deg, #c8005d 0%, #ed2345 100%);

  .vl-icon {
    color: currentColor;
    fill: currentColor;
  }
}

.vl-badge--action {
  background: $vl-accent-color;

  .vl-icon {
    color: currentColor;
    fill: currentColor;
  }
}
