.vl-progress-bar {
  &__step,
  &__step--active {
    &::before {
      background-color: $vl-action-color; // bar
    }
  }

  &__bullet,
  &__step--active .vl-progress-bar__bullet {
    background-color: $vl-action-color;
    border-color: $vl-action-color;
  }

  &__bullet::before {
    background-color: $vl-action-color;
  }
}
