.voucher_declaration_edit_row {
  .alignedCel {
    display: block;
    padding-top: 0.9rem;
  }

  .vl-input-field--block.vl-input-field--error {
    width: auto;

    @media screen and (min-width: 1023px) {
      width: 100%;
    }
  }
}
